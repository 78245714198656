<template>
  <div>
    <h1 class="mb-5">Bize Ulaşın</h1>
    <b-form
      class="form fv-plugins-bootstrap fv-plugins-framework"
      @submit.stop.prevent="onSubmit"
    >
      <Card>
        <template #header>İletişim Formu </template>

        <Input
          label="Başlık"
          id="form.title"
          v-model="form.title"
          :state="validateState('title')"
          :vError="$v.form.title"
          :disabled="this.readOnly"
          placeholder="Örnek: Rapor hakkında"
        />

        <Input
          label="Konu"
          id="form.subject"
          v-model="form.subject"
          :state="validateState('subject')"
          :vError="$v.form.subject"
          :disabled="this.readOnly"
          placeholder="Seçiniz"
        />
        <!-- TODO : Bu dropdown olacak. -->
        <Textarea
          label="Mesajınız"
          id="form.message"
          v-model="form.message"
          :state="validateState('message')"
          :vError="$v.form.message"
          :disabled="this.readOnly"
          rows="5"
          max-rows="8"
        /> 

        <template #footer>
          <div class="text-right">
            <b-button
              variant="primary"
              class="px-6 py-4 font-weight-bolder"
              ref="submitButton"
              type="submit"
              :disabled="readOnly"
              @click="onSubmit"
              >GÖNDER
            </b-button>
          </div>
        </template>
      </Card>
    </b-form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'contactUs',
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bize Ulaşın', route: '/bize-ulasin' },
    ]);
  },
  data() {
    return {
      readOnly: false,
      form: {
        title: '',
        subject: '',
        message: '', 
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      subject: {
        required,
      },
      message: {
        required,
      }, 
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.readOnly = true;
      this.submitButton = this.$refs['submitButton'];
      this.$spinnerLoader.addSpinnerLoader(this.submitButton);

      let payload = {
        title: this.form.title,
        subject: this.form.subject,
        message: this.form.message,
      };

      this.$dbFunctions
        .insertOrUpdate(`contact-us`, '', payload)
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.formReset();
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
        })
        .finally(() => {
          this.readOnly = false;
          this.$spinnerLoader.removeSpinnerLoader(this.submitButton);
        });
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  }, 
};
</script>
